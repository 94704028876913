import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  root: {
    minHeight: '20rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    root: {
      minHeight: '40rem',
    },
  },
}))

export default useStyles
