import React from 'react'
import {
  LayoutCheckout,
  Loading,
  SEO,
} from '../../ui'
import useStyles from './checkout-payment-processing-style'

function CheckoutPaymentProcessingView({
  seoTitle,
}) {
  const { root } = useStyles()

  return (
    <LayoutCheckout
      disableFooter
      disablePaymentFooter
    >
      <SEO title={seoTitle} />
      <div className={root} />
      <Loading />
    </LayoutCheckout>
  )
}

export default CheckoutPaymentProcessingView
